import { IonToast } from "@ionic/react";
import {
  TOAST_COLOR_DEFAULT,
  TOAST_DURATION_DEFAULT,
} from "@feature/toast/toastConstants";
import { ToastOptions } from "@ionic/core/dist/types/components/toast/toast-interface";
import {
  getToastState,
  toastDone,
} from "../slice/toastSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";

export const ToastOutlet = () => {
  const dispatch = useAppDispatch();
  const toastState = useAppSelector(getToastState);

  const renderToast = (toastOption: ToastOptions) => {
    return (
      <IonToast
        position="bottom"
        isOpen={true}
        duration={toastOption.duration ?? TOAST_DURATION_DEFAULT}
        color={toastOption.color ?? TOAST_COLOR_DEFAULT}
        {...toastOption}
        onDidDismiss={() => dispatch(toastDone(toastOption.id ?? ""))}
        key={`toast-${ toastOption.id }`}
      />
    );
  };

  return <>{toastState.pool.map(renderToast)}</>;
};
