import { ApiData } from "@feature/api/component/ApiData";
import {
  HistorySerie,
  HistoryWorkout,
} from "@common/type-graphql/generated";
import { HistorySerieCommentComponent } from "@feature/history/component/HistorySerieCommentComponent";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import { Serie } from "@common/model/Serie";
import { SerieComponent } from "@feature/serie/component/serieComponent";
import {
  Trans,
  t,
} from "@lingui/macro";
import { confirmDelete } from "@feature/confirm/service/delete";
import { deletePayloadInput } from "@util/deletePayloadInput";
import {
  historyController,
  useAppDispatch,
} from "@core/redux/store";
import { routes } from "@core/route";
import { toast } from "@feature/toast/slice/toastSlice";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import {
  useEffect,
  useState,
} from "react";
import {
  useHistorySerieEditMutation,
  useHistorySerieGetQuery,
  useHistoryWorkoutGetQuery,
} from "@feature/history/api/historyApi";
import { useIonActionSheet } from "@ionic/react";
import { useSerieCalculate } from "@feature/serie/hook/useSerieCalculate";

type Props = {
  historySerieUuid: string;
  historySeriesListLinked?: HistorySerie[];
  showChips?: boolean;
}

export const HistorySerieViewComponent = (props: Props) => {
  const historySerieApi = useHistorySerieGetQuery({ where: { uuid: props.historySerieUuid } });
  const historyWorkoutApi = useHistoryWorkoutGetQuery({ where: { uuid: historySerieApi.data?.historyWorkoutUuid } }, { skip: !historySerieApi.isSuccess || !historySerieApi.data });

  return <>
    <ApiData endpoint={[
      historySerieApi,
      historyWorkoutApi,
    ]}>
      <HistorySerieView
        historySerie={historySerieApi.data}
        historWorkout={historyWorkoutApi.data}
        currentHistorySerieUuid={props.historySerieUuid}
        historySeriesListLinked={props.historySeriesListLinked}
        showChips={props.showChips}
      />
    </ApiData>
  </>;
};

type PropsHistorySerie = {
  historySerie: HistorySerie;
  historWorkout: HistoryWorkout;
  currentHistorySerieUuid?: string;
  historySeriesListLinked?: HistorySerie[];
  showChips?: boolean;
}

const HistorySerieView = (props: PropsHistorySerie) => {
  const dispatch = useAppDispatch();

  const protocolUuid = props.historWorkout.protocolUuid;
  const exerciseUuid = props.historWorkout.exerciseUuid;
  const profileUuid = props.historWorkout.profileUuid;

  const {
    endpoints: serieCalculateEndpoints,
    calculateHistorySerie,
  } = useSerieCalculate();

  const {
    endpoints: configurationEndpoints,
    getHistoryWorkoutConfigurationValues,
    getHistorySerieConfigurationValues,
  } = useConfiguration();

  const {
    isApiDataReady,
  } = useApiDataLogic([
    ...serieCalculateEndpoints,
    ...configurationEndpoints,
  ]);

  const [
    serie,
    setSerie,
  ] = useState<Serie | null>(null);

  // Calculate the algorithm
  useEffect(() => {
    if (
      !isApiDataReady ||
      serie
    ) {
      return;
    }

    const calculatedSerie = calculateHistorySerie(props.historySerie, props.historWorkout);
    if (calculatedSerie) {
      setSerie(calculatedSerie);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isApiDataReady ]);

  const [ edit ] = useHistorySerieEditMutation();
  const [ present ] = useIonActionSheet();

  const historyWorkoutConfigurationValues = getHistoryWorkoutConfigurationValues(props.historWorkout);
  const historySerieConfigurationValues = getHistorySerieConfigurationValues(props.historySerie);

  const onDelete = async() => {
    confirmDelete({
      header: t`Delete this serie`,
      present: present,
      action: async() => {
        await edit({
          data: deletePayloadInput(),
          where: { uuid: props.currentHistorySerieUuid },
        }).unwrap();
        dispatch(toast({ message: t`Serie deleted successfully.` }));
        historyController.replace(routes.historyList.redirectPath);
      },
    });
  };

  if (
    !isApiDataReady ||
    !serie
  ) {
    return null;
  }

  return <>
    <SerieComponent
      serie={serie}
      protocolUuid={protocolUuid}
      exerciseUuid={exerciseUuid}
      profileUuid={profileUuid}
      workoutConfigurationValues={historyWorkoutConfigurationValues}
      serieConfigurationValues={historySerieConfigurationValues}
      isLive={false}
      currentHistorySerieUuid={props.currentHistorySerieUuid}
      historySeriesListLinked={props.historySeriesListLinked}
      showChips={props.showChips}
    />

    <SeparatorUi />

    <h4 style={{
      textAlign: "center",
      marginBottom: 0,
    }}>
      <Trans>Actions</Trans>
    </h4>

    <HistorySerieCommentComponent
      uuid={props.currentHistorySerieUuid}
    />

    <SeparatorUi />

    <PrimaryDestructiveButtonUi
      label={t`Delete this serie`}
      onClick={onDelete}
    />

    <SeparatorUi />
  </>;
};
