
import { PowerReferenceValue } from "@common/model/PowerReferenceValue";
import { Serie } from "@common/model/Serie";
import { Trans } from "@lingui/macro";

type Props = {
  serie: Serie;
  powerReference?: PowerReferenceValue;
  loadWeightUsed?: number;
  listCurrentPosition?: number;
  listCount?: number;
}

export const DetailsPhasesComponent = (props: Props) => {
  return <>
    <h4 style={{
      textAlign: "center",
      marginBottom: 0,
    }}>
      <Trans>Details</Trans>
    </h4>
    {
      props.listCurrentPosition > 0 &&
      <p>
        <Trans>Serie:</Trans> <strong>{ props.listCurrentPosition } / { props.listCount }</strong>
      </p>
    }
    {
      props.powerReference &&
		  <p>
			  <Trans>Power reference:</Trans> <strong>{ props.powerReference } W</strong>
		  </p>
    }
    {
      props.loadWeightUsed &&
      <p>
        <Trans>Load weight used:</Trans> <strong>{ props.loadWeightUsed } Kg</strong>
      </p>
    }
  </>;
};
