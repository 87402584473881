import { AccountViewComponent } from "@feature/account/component/accountViewComponent";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";

export const AccountViewPage = () => {
  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.accountView.title)}
        backToRoute={routes.settings} />
      <IonContent>
        <AccountViewComponent />
      </IonContent>
    </IonPage>
  </>;
};
