import {
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "@core/redux/store";
import { STORE_TOAST_KEY } from "@feature/toast/toastConstants";
import { ToastOptions } from "@ionic/core/dist/types/components/toast/toast-interface";
import { djb2Hash } from "@util/dbb2hash";
import { i18n } from "@lingui/core";

interface InitialStateModelInterface {
  pool: ToastOptions[];
}

const initialState: InitialStateModelInterface = { pool: [] };

export const toastSlice = createSlice({
  name: "toast",
  initialState: initialState,
  reducers: {
    toast: (state, action: PayloadAction<ToastOptions>) => {
      const id = djb2Hash(action.payload.message);
      if (!state.pool.find(toast => toast.id === id)) {
        state.pool.push({
          position: action.payload.position ?? "bottom",
          message: action.payload.message,
          color: action.payload.color ?? "warning",
          id: id,
          buttons: [ { text: i18n._(/*i18n*/"Ok") } ],
        });
      }
    },
    toastUntilOk: (state, action: PayloadAction<ToastOptions>) => {
      const id = djb2Hash(action.payload.message);
      if (state.pool.find(toast => toast.id === id)) {
        return;
      }
      state.pool.push({
        position: action.payload.position ?? "bottom",
        message: action.payload.message,
        color: action.payload.color ?? "warning",
        id: id,
        buttons: action.payload.buttons ?? [ { text: i18n._(/*i18n*/"Ok") } ],
        duration: 0,
      });
    },
    toastDone: (state, action: PayloadAction<string | undefined>) => {
      state.pool = state.pool.filter(toast => toast.id !== action.payload);
    },
    toastDoneAll: (state, action: PayloadAction<string | undefined>) => {
      state.pool = [];
    },
  },
});

export const {
  toast,
  toastUntilOk,
  toastDone,
  toastDoneAll,
} = toastSlice.actions;

export const getToastState = (state: RootState) => state[STORE_TOAST_KEY];
