import { ApiData } from "@feature/api/component/ApiData";
import { HistorySerieViewComponent } from "@feature/history/component/historySerieViewComponent";
import {
  useHistorySerieGetQuery,
  useHistorySeriesListLinkedToHistoryTrainingSessionQuery,
} from "@feature/history/api/historyApi";
import { useParams } from "react-router-dom";

type Props = {
  historySerieUuid?: string;
  profileUuid?: string;
  showChips?: boolean;
}
type Params = {
  historySerieUuid: string;
}

export const HistorySerieSwiperViewComponent = (props: Props) => {
  const params = useParams<Params>();

  const serieUuid = params.historySerieUuid ?? props.historySerieUuid;

  const historySerieGetApi = useHistorySerieGetQuery({ where: { uuid: serieUuid } }, { skip: !serieUuid });

  const historySeriesListLinkedToHistoryTrainingSessionApi = useHistorySeriesListLinkedToHistoryTrainingSessionQuery({
    where: {
      uuid: serieUuid,
      profileUuid: props.profileUuid,
    },
  }, { skip: !serieUuid });

  if (!serieUuid) {
    return <></>;
  }

  return <>
    <ApiData endpoint={[
      historySerieGetApi,
      historySeriesListLinkedToHistoryTrainingSessionApi,
      historySeriesListLinkedToHistoryTrainingSessionApi,
    ]}>
      <HistorySerieViewComponent
        historySerieUuid={serieUuid}
        historySeriesListLinked={historySeriesListLinkedToHistoryTrainingSessionApi.data}
        showChips={props.showChips}
      />
    </ApiData>
  </>;
};
